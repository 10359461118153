import React from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { SearchResultListProps } from './models';
import './SearchResultList.scss';

const SearchResultList = React.forwardRef<HTMLUListElement, SearchResultListProps>(
  ({ searchResults, className }, ref) => (
    <ul ref={ref} className={classNames('search-result-list', className)}>
      {searchResults.map(({ url, title, description }) => (
        <li key={url} className="search-result-list__item">
          <Button className="search-result-list__item-link" link={url}>
            <h5 className="search-result-list__item-title">{title}</h5>
            <DangerouslySetInnerHtml
              element="p"
              className="search-result-list__item-description"
              html={description}
            />
          </Button>
        </li>
      ))}
    </ul>
  )
);

export default SearchResultList;
